@use 'variables';
@use 'mixins' as m;

.accordion-bio {
    img{
        width: 100%;
        max-width: 200px;
        display: block;
        margin: 1rem auto;
        border-radius: 4px;
    }
}

.Mui-expanded {
    color: variables.$cerulian;
    svg {
        fill: variables.$cerulian;
    }
}

#dark .Mui-expanded {
    svg {
        fill: variables.$cerulian;
    }
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters {
    align-items: center;
    svg {
        padding-right: 20px;
    }
}

#dark {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {    
        background-color: rgba(75, 75, 75, .75);
        color: variables.$gallery;
    }

    .about-section .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
        color: variables.$gallery;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters, .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters{
        @include m.dark-mode-shadow;

    }
}

.filter-container{
    padding-left: 0;
    img{
        filter: grayscale(25%);
        padding: 10px;
        width: 60px;
        height: 88px;
    }
}

.selector-buttons {
    text-align: center;
    
    button {
        width: 90px;
        height: 30px; 
        font-family: 'Poppins', sans-serif;
        background-color: variables.$gallery;
        border: 1px solid rgba(75, 75, 75, .75);
    }

    .button-checked {
        background-color: variables.$cerulian;
        color: variables.$white;
    }
}

.education-accordion {
    h3, h4, p {
        margin: 0;
    }

    .education-card {
        margin: 2rem;
        .education-institution {
            font-weight: 600;
        }
        
        .education-major {
            font-weight: 500;
        }

        .education-duration{
            font-style: italic;

            @media screen and(min-width:600px) {
            padding-left: 1rem;
            }
        }

        .education-notes {
            padding-left: 1rem;
            font-size: 90%;
            @media screen and(min-width:600px) {
                padding-left: 2rem;
            }
        }
    }
}
