@use 'variables';

html {
    scroll-behavior: smooth;
}

.content-area{
    padding: 0 .5rem;
    max-width: 700px;
    margin: 0 auto;

    @media screen and (min-width: 900px){
        max-width: 1500px;
        display: grid;
        grid-template-columns: minmax(30px, 1fr) minmax(420px, 1000px) minmax(30px, 1fr) 150px;

        .grid-align{
            grid-column-start: 2;
        }
    }
}

figure {
    margin-left: 0;
    margin-right: 0;
}

section{
    min-height: 100vh;
}

.about-section, .projects-section{
    padding: 3.75rem 0 25rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .grid-align {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.error-message {
    grid-column-start: 2;
    text-align: center;
    min-height: 100vh;
}


.skip-to-content-link {
    z-index:500;
    position: absolute;
    top: 0;
    transform: translateY(-120%);  
    left: 90px;
    transition: transform 0.3s;
    padding: 5px;
    background-color: variables.$honolulu;
    color: variables.$gallery;
  }

  .skip-to-content-link:focus {
    transform: translateY(0%);
  }
