.headerbar{
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    backdrop-filter: blur(15px);
    z-index:10;

    .small-nav-logo {
        width: 35px;
        position: fixed;
        right: 10px;
        top: 5px;
    }
    
    @media screen and (min-width: 900px){
        display: none;
    }
}