@use 'mixins' as m;
@use 'variables';

.project-home-section {
    padding: 60px 0;
    grid-column-start: 2;

    h1, h2, h3 {
        text-align: center;
    }
}

.project-featured-image {
    width: 100%;
    object-fit: cover;
    min-height: 150px;   
    border-radius: 4px;     
    }

.project-details {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.project-section {
    margin: 2rem 0;
}

.project-tools-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
        margin: 10px;
    }
}

#light{
    .project-section {
        @include m.light-mode-shadow;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;    
        padding: 1rem;
    }
}

#dark{
    .project-section {
        @include m.dark-mode-shadow;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;    
        padding: 1rem;
    }

    pre {
        background: variables.$gallery !important; 
    }
}

.project-navigation {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .link-button {
        margin: 10px 0;
    }

    @media screen and (min-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.link-button.git-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    svg {
        padding-right:5px;
    }
}

.project-image {
    width: 100%;
}

    .project-bottom-images {
        @media screen and (min-width: 700px){
            display: flex;

            figure {
                width: 50%;
            }
        }
    }
    .project-image-3, .project-image-4 {
        width: 94%;
        display: block;
        margin: 0 auto;
        padding: 5px;

        @media screen and (min-width: 700px){
        max-width: 400px;
        margin: 0 auto;
        }
    }


