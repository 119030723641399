.fourohfour-gif {
    width: 100%;
    max-width: 480px;
}

.error-message {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;


}