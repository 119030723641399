@use 'variables';

.home-section{
    text-align: center;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
    flex-direction: column;
    line-height: 1;
}

.home-water {
	position: relative;
}

.home-water h1 {
	color: #fff;
	font-size: 4em;
    margin: 0;

	@media screen and(min-width: 383px){
		font-size: 5em;
	}
}

#dark .home-water h1:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px variables.$honolulu;
}

.home-water h1:nth-child(1) {
    position: absolute;
	color: transparent;
	-webkit-text-stroke: 2px variables.$honolulu;
}

.home-water h1:nth-child(2) {
	color: variables.$honolulu;
	animation: animate 4s ease-in-out infinite;
}


@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

.invisible {
    visibility: hidden;
}

