@use 'variables';

.bottom-nav{
    z-index:200;
    position:fixed;
    bottom: 0;
    background-color: variables.$honolulu;
    width: 100%;
    height: 60px;

    ul{
        list-style: none;
        display: flex;
        justify-content: space-evenly;
        padding: 0 10px;
        margin: 0;
    }

    li{
        padding-top: 7px;
        width: 70px;
        text-align: center;
        line-height: 1;
    }

    path{
        fill: white;
    }

    p{
        color: white;
        text-transform: uppercase;
        font-weight:300;
        font-size: 87%;
        margin-top: 0;
      }

      @media screen and (min-width: 900px){
        display: none;
      }
}