@use 'variables';

.navbar{
    display: none;
    background-color: variables.$honolulu;
    color: variables.$white;
    text-align: right;

    .nav-logo{
        width: 55px;
        padding: 5px;
    }

    .navbar-text{
        text-align:center; 
        height: 64px;
    }

    svg{
        padding-right: 12px;
    }
    
    ul{
        list-style: none;
        padding-left: 20px;
        text-transform: uppercase;
        
        path{
            fill: variables.$white;
        }
    }

    @media screen and (min-width: 900px){
        position:fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #0076B6;
        list-style: none;
        overflow:hidden;
        -webkit-transition:width .3s linear;
        transition:width .3s linear;
        -webkit-transform:translateZ(0) scale(1,1);
        z-index:1000;
    }
}

.lightswitch {
    position: fixed;
    top: 3px;
    left: 10px;
    z-index: 99999;
}

.navbar:hover,nav.main-menu.expanded{
    width:150px;
    overflow:visible;

}

.navbar-text, .nav-button {
    p{
        visibility: hidden;
        white-space: nowrap;
    }
}

.navbar:hover{
    .navbar-text, .nav-button{
        p{
            visibility: visible;
        }
    }
}

.nav-button{
    display: flex;
    align-items: center;
    color: variables.$white;
}



