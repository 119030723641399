@mixin dark-mode-shadow {
    box-shadow: 0px 2px 1px -1px rgb(255 255 255 / 20%), 
    0px 1px 1px 0px rgb(255 255 255 / 14%), 
    0px 1px 3px 0px rgb(255 255 255 / 12%);
}

@mixin light-mode-shadow {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 
    0px 1px 1px 0px rgb(0 0 0 / 14%), 
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

@mixin mui-override {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
}