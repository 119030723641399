@use 'variables';
@use 'mixins' as m;

.projects-section {
    .featured-image{
        width: 100%;
        max-width: 800px;
        display: block;
        margin: 0 auto;
        object-fit: cover;
        min-height: 150px;
        border-radius: 4px;
    }
}

#light{
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
        color: variables.$mineshaft;
        @include m.mui-override;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        color: variables.$honolulu;
        @include m.mui-override;
    }
}

#dark{
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
        color: variables.$gallery;
        @include m.mui-override;
    }

    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected{
        color: variables.$cerulian;
        @include m.mui-override;
    }
}


