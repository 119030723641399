.all-projects-section {
    grid-column-start: 2;
    margin: 60px 0 80px;

    h1  {
        text-align: center;
    }
}

.all-projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.single-all-project {
    max-width: 400px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;

    h3 {
        text-align: center;
    }
    p {
        font-size: .8rem;
    }
}

.small-button-links{
    text-align: center;

    button{
        margin: 0 5px;
    }
}

