@use 'variables';

.loader {
    width: 48px;
    height: 48px;
    border-width: 3px;
    border-style: dashed solid  solid dotted;
    border-color: variables.$honolulu variables.$honolulu transparent variables.$honolulu;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px -40px;
    z-index: 1000;

  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 20px;
    top: 31px;
    border: 10px solid transparent;
    border-right-color: variables.$honolulu;
    transform: rotate(-40deg);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  