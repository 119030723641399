@use 'variables';

.link-button-wrapper {
    text-align: center;
    width: 170px;
    margin: 0 auto;

    a{
      width: 100%;
    }
}

.link-button {
  font-weight: 500;
  border: none;
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  height: 36px;
  width: 170px;
  transition: all .7s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: variables.$mineshaft;
    border-radius: 10px;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: variables.$honolulu;
    transition: all .7s;
    border-radius: 10px;
    z-index: -1;
  }
  &:hover {
    color: variables.$white;
    &:before {
      width: 100%;
    }
  }
}

#dark{
    .link-button{
        color: variables.$mineshaft;
        &:after {
            background-color: variables.$gallery;
    }
        &:hover{
            color: variables.$white;
        }
}
}

.link-button-small {
  height: 25px;
  width: 100px;
  text-align: center;
  font-weight: 500;
  border: none;
  display: inline-block;
  border-radius: 10px;
  color: #fff;
}

.small-button {
  width: 80px;
  height: 25px;
}

.top-arrow {
  text-align: center;
  svg{
    fill: variables.$honolulu;
  }}

  // .all-projects-button {
  //   margin-bottom: 20px;
  // } removed 1/11/2023