@use 'variables';

#dark {
  background-color: variables.$mineshaft;
  color: variables.$gallery;

  h1, h2{
    color: variables.$cerulian;
  }
  .headerbar{
    background-color: rgba(variables.$mineshaft,.45);
  }

  .contact-box{
    svg{
      fill: variables.$gallery;
    }
    svg:hover{
      fill: variables.$honolulu;
    }
  }

  .content-area{
    img {
    filter: brightness(.9);
  }
}

}

#light {
    color: variables.$mineshaft;
    background-color: variables.$white;

    .headerbar{
      background-color: rgba(variables.$white,.55);
    }
    .contact-box{
      svg{
        fill: variables.$mineshaft;
      }

      svg:hover{
        fill: variables.$honolulu;
      }
    }
  }

    .filter-item{
      padding: 0 10px;
    }