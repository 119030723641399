@use 'variables';
@use 'mixins' as m;

.contact-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-box{
    @include m.light-mode-shadow;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    padding: 1rem;
    margin-bottom:100px;
    text-align: center;
    font-size: 125%;

    svg{
        height: 36px;
        width: 36px;
    }

    svg:hover{
        fill: variables.$honolulu;
    }

}

#dark{
    .contact-box{
        @include m.dark-mode-shadow;
    }
}
